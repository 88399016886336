import { gql } from './gql'

export const CREATE_ENVIRONMENT_PG_DATASOURCE = gql(`
  mutation CreateEnvironmentPgDatasource(
    $connectionString: String!
    $environmentId: String!
  ) {
    createEnvironmentPgDatasource(
      connectionString: $connectionString
      environmentId: $environmentId
    ) {
      datasourceId
      initialSchemamapSql
      datasource {
        id
        host
        port
        database
        createdAt
        updatedAt
      }
    }
  }
`)

export const CHECK_DATASOURCE = gql(`
  query CheckDatasource($datasourceId: String!) {
    checkDatasource(datasourceId: $datasourceId) {
      connected
      migrations {
        installedRank
        version
        description
        type
        script
        checksum
        installedBy
        installedOn
        executionTime
        success
      }
    }
  }
`)

export const DELETE_DATASOURCE = gql(`
  mutation DeleteDatasource($datasourceId: bigint!) {
    deleteDatasourcesByPk(id: $datasourceId) {
      id
      host
    }
  }
`)

export const LATEST_DATASOURCE_I18N = gql(`
  query LatestDatasourceI18n($datasourceId: bigint!) {
    datasourceI18ns(
      where: { datasourceId: { _eq: $datasourceId } }
      orderBy: { lastSeenAt: DESC }
      limit: 1
    ) {
      id
      value
      valueHash
      lastSeenAt
      createdAt
      updatedAt
    }
  }
`)

export const ASSIGN_PG_TUNNEL_TO_ENVIRONMENT = gql(`
  mutation AssignPgTunnelPort($environmentId: bigint!) {
    assignPgtunnelToEnvironment(args: {_environment_id: $environmentId}) {
      id
      port
      token
      createdAt
      updatedAt
    }
  }
`)
