import { chakra, useColorModeValue } from '@chakra-ui/react'

export const SchemamapLogo: React.FC = (props) => {
  const color = useColorModeValue('#231f20', '#fff')
  return (
    <chakra.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="3.64 -1.99 92.35 103.35"
      {...props}
    >
      <path
        fill={color}
        d="M93.978 22.513l-.012-.075a3.072 3.072 0 0 0-.073-.34l-.016-.058a3.126 3.126 0 0 0-.135-.368l-.036-.072c-.028-.06-.056-.12-.088-.179-.019-.033-.04-.063-.06-.095a3.179 3.179 0 0 0-.267-.38l-.018-.023a3.254 3.254 0 0 0-.276-.286.958.958 0 0 0-.059-.049 2.871 2.871 0 0 0-.27-.21l-.055-.039a3.157 3.157 0 0 0-.343-.196c-.008-.003-.015-.009-.021-.013l-20.518-9.854L51.217.423a3.1 3.1 0 0 0-2.683 0l-20.517 9.854L7.5 20.13l-.021.012a3.266 3.266 0 0 0-.345.197l-.055.038a2.967 2.967 0 0 0-.27.21l-.059.05a3.081 3.081 0 0 0-.275.284l-.023.03c-.072.088-.14.182-.204.278-.018.034-.038.066-.058.099-.019.031-.04.061-.058.093-.032.058-.058.116-.085.175-.013.026-.027.051-.039.078-.053.12-.098.242-.134.365l-.017.065a2.98 2.98 0 0 0-.072.332l-.013.08a3.015 3.015 0 0 0-.028.401v50.596c0 1.126.611 2.164 1.596 2.71l19.928 11.051c.178.163.373.31.589.431L48.373 99.08l.004.002a2.84 2.84 0 0 0 .398.182 4.134 4.134 0 0 0 .377.117c.089.021.179.038.271.052l.091.014a3.066 3.066 0 0 0 .72 0l.092-.014a2.95 2.95 0 0 0 .649-.169l.059-.021c.115-.047.229-.1.338-.161l.005-.002 20.517-11.376a3.09 3.09 0 0 0 .588-.431l19.929-11.051a3.1 3.1 0 0 0 1.596-2.71V22.918c0-.135-.012-.27-.029-.405zM11.94 28.184l14.32 7.941v18.262l-14.32-7.941zm17.418-11.678l13.772 6.615-13.772 7.637-13.772-7.637zm41.034 0l13.772 6.615-13.772 7.637-13.772-7.637zM49.875 67.482l-14.129-7.835 14.129-7.835 14.128 7.835zm17.419-13.095l-14.32-7.941V28.184l14.32 7.941zm-20.517-7.942l-14.321 7.941V36.125l14.321-7.941zm-14.32 18.462l14.321 7.942V91.11l-14.321-7.94zm20.517 7.942l14.32-7.941V83.17l-14.32 7.94zM73.49 54.387V36.125l14.321-7.941v18.262zM49.875 6.652l13.361 6.417-13.361 6.417-13.361-6.417zM11.94 53.53l14.32 7.941V79.63l-14.32-7.941zm61.55 26.101V61.472l14.321-7.941V71.69z"
      />
    </chakra.svg>
  )
}
