import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  Portal,
  useColorMode,
  Button,
  HStack,
  Box,
  Text,
} from '@chakra-ui/react'

import { useHotkeysShortcut } from '@saas-ui/react'
import { Has } from '@saas-ui-pro/feature-flags'

import { useHelpCenter } from '@ui/lib'
import { usePath } from '../hooks/use-path'
import { useCurrentUser } from '../hooks/use-current-user'
import { useInitApp } from '../hooks/use-init-app'
import { FiSettings } from 'react-icons/fi'
import { UserButton } from '@clerk/clerk-react'
import React from 'react'

export const UserMenu = () => {
  const { logOut } = useInitApp()

  const logOutAndClearCache = () => {
    logOut()
  }

  const { user: currentUser } = useCurrentUser()
  const { toggleColorMode, colorMode } = useColorMode()

  const help = useHelpCenter()
  const helpCommand = useHotkeysShortcut('general.help', () => {
    help.open()
  })

  const logoutCommand = useHotkeysShortcut('general.logout', () => {
    logOutAndClearCache()
  })

  // Click UserButton to simulate whole row being clickable
  return (
    <HStack
      ml={2}
      mt={2}
      onClick={(e) => {
        const userButton = document.querySelector(
          '.cl-userButtonTrigger',
        ) as HTMLButtonElement
        userButton?.click()
        e.preventDefault()
      }}
      _hover={{ cursor: 'pointer' }}
    >
      <UserButton />
      <Text ml={'4px'}>{currentUser?.fullName}</Text>
    </HStack>
  )
  /*
  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<FiSettings />}
        variant="ghost"
        textAlign="left"
        aria-label="User menu"
        _hover={{
          bg: 'sidebar-on-muted',
        }}
        _active={{
          bg: 'sidebar-on-subtle',
        }}
      >
        Settings
      </MenuButton>
      <Portal>
        <MenuList zIndex={['modal', null, 'dropdown']}>
          <MenuItem
            onClick={() =>
              window.open(
                `https://schemamap.productlane.com/changelog?email=${currentUser?.primaryEmailAddress?.emailAddress}`,
                '_blank',
              )
            }
          >
            Changelog
          </MenuItem>
          <MenuItem command={helpCommand} onClick={() => help.open()}>
            Help
          </MenuItem>
          <MenuItem
            onClick={() =>
              window.open(
                `https://schemamap.productlane.com/roadmap?email=${currentUser?.primaryEmailAddress?.emailAddress}`,
                '_blank',
              )
            }
          >
            Feedback
          </MenuItem>

          <MenuDivider />
          <MenuItem
            command={logoutCommand}
            onClick={() => logOutAndClearCache()}
          >
            Log out
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
  */
}
