import { Box } from '@chakra-ui/react'

import { OrganizationSwitcher } from '@clerk/clerk-react'

export const WorkspacesMenu: React.FC = (props) => {
  return (
    <Box width={'full'} ml={2}>
      <OrganizationSwitcher
        hidePersonal
        createOrganizationMode={'navigation'}
        createOrganizationUrl={'/getting-started'}
      />
    </Box>
  )
}
