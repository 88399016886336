import { HotkeysConfig } from '@saas-ui/hotkeys'

import { platformSelect } from '@saas-ui-pro/react'

export const appHotkeys: HotkeysConfig = {
  general: {
    title: 'General',
    hotkeys: {
      help: {
        label: 'Help & support',
        command: '?',
      },
      search: {
        label: 'Search',
        command: '/',
      },
      filter: {
        label: 'Add filter',
        command: 'F',
      },
      logout: {
        label: 'Log out',
        command: platformSelect({ mac: '⌥ ⇧ Q' }, 'Ctrl+Shift+Q'),
      },
    },
  },
  navigation: {
    title: 'Navigation',
    hotkeys: {
      dashboard: {
        label: 'Go to Dashboard',
        command: 'G then D',
      },
      projects: {
        label: 'Go to Projects',
        command: 'G then C',
      },
      schemaOverview: {
        label: 'Go to Schema Overview',
        command: 'G then O', // G then S does not work
      },
      tenants: {
        label: 'Go to Tenants',
        command: 'G then T',
      },
      mdes: {
        label: 'Go to Master Data Entitites',
        command: 'G then M',
      },
      postgres: {
        label: 'Go to Postgres',
        command: 'G then P',
      },
      googleSheets: {
        label: 'Go to Google Sheets',
        command: 'G then E', // G then S does not work
      },
    },
  },
  projects: {
    title: 'Projects',
    hotkeys: {
      add: {
        label: 'Add a project',
        command: 'A',
      },
    },
  },
  googleSheets: {
    title: 'Google Sheets',
    hotkeys: {
      add: {
        label: 'Add a Google Sheet',
        command: 'A',
      },
    },
  },
  environments: {
    title: 'Environments',
    hotkeys: {
      add: {
        label: 'Add an environment',
        command: 'A',
      },
    },
  },
  settings: {
    hotkeys: {
      close: {
        label: 'Close settings',
        command: 'Esc',
      },
    },
  },
}
