import React from 'react'

export type BillingStatus = 'active' | 'canceled' | 'past_due'

export interface BillingPlan {
  id: string
  name: string
  period: 'monthly' | 'yearly' | string
  features: Record<string, boolean | string | number>
  slug: string
  price: string
}

export interface BillingOptions {
  plans: BillingPlan[]
  currentPlan: BillingPlan
  planId?: string
  status?: BillingStatus
  startedAt?: Date
}

interface BillingContextValue extends BillingOptions {
  isReady: boolean
  isCanceled: boolean
  currentPlan: BillingPlan
}

export const BillingContext = React.createContext<BillingContextValue | null>(
  null,
)

export interface BillingProviderProps {
  value: BillingOptions
  children: React.ReactNode
}

export const BillingProvider: React.FC<BillingProviderProps> = (props) => {
  const {
    children,
    value: { plans, planId, status, startedAt, currentPlan },
  } = props

  const context = React.useMemo(() => {
    return {
      plans,
      isReady: true,
      isCanceled: status === 'canceled',
      planId,
      currentPlan,
      status,
      startedAt,
    }
  }, [plans, status, planId, currentPlan, startedAt])

  return (
    <BillingContext.Provider value={context}>
      {children}
    </BillingContext.Provider>
  )
}

export const useBilling = () => {
  return React.useContext(BillingContext) as BillingContextValue
}

export const useCurrentPlan = () => {
  const { currentPlan } = useBilling()
  return currentPlan
}
