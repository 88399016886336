import * as React from 'react'

import { IconContext } from 'react-icons'

import { SaasProvider } from '@saas-ui/react'
import { AuthProvider, AuthProviderProps } from '@saas-ui/auth'

import { FeaturesProvider } from '@saas-ui-pro/feature-flags'

import { I18nProvider } from '@app/i18n'

import { theme } from '@ui/theme'
import { ModalsProvider } from '@ui/lib'

import { appHotkeys, segments } from '@app/config'

import { Hotkeys } from '../components/hotkeys'
import { ClerkAuthProvider } from '@saas-ui/clerk'

import { ClerkLoading, ClerkLoaded } from '@clerk/nextjs'

import { ApolloProviderWrapper } from '@api/client'
import { dark } from '@clerk/themes'
import { useRouter } from 'next/router'
import { AppLoader } from '@ui/lib'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import { Crisp } from 'crisp-sdk-web'
import { posthog } from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

export interface AppProviderProps {
  linkComponent?: React.ElementType<any>
  authService?: AuthProviderProps
  onError?: (error: Error, info: any) => void
  children: React.ReactNode
}

// NOTE: maybe elide with __DEV__ to reduce bundle size
loadDevMessages()
loadErrorMessages()

if (
  typeof window !== 'undefined' &&
  !window.location.host.includes('127.0.0.1') &&
  !window.location.host.includes('localhost')
) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY ?? '', {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug()
    },
  })

  // https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/npm/
  Crisp.configure('ad7ab300-bd5d-410f-86eb-136d1d846a73', {
    autoload: false,
  })
}

export const AppProvider: React.FC<AppProviderProps> = (props) => {
  const { linkComponent, onError, authService, children } = props

  const router = useRouter()

  React.useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview')
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  return (
    <ClerkAuthProvider
      appearance={{
        baseTheme: dark,
        layout: {
          termsPageUrl: 'https://schemamap.io/terms',
          privacyPageUrl: 'https://schemamap.io/privacy',
        },
        variables: {
          colorPrimary: theme.colors.primary[500],
          colorTextOnPrimaryBackground: theme.colors.primary[50],

          colorBackground: theme.colors.gray[900],
        },
      }}
      navigate={(to: string) => {
        router.push(to)
      }}
      publishableKey={process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY}
    >
      {({ clerk, authService }) => (
        <>
          <ClerkLoading>
            <AppLoader />
          </ClerkLoading>
          <ClerkLoaded>
            <PostHogProvider client={posthog}>
              <ApolloProviderWrapper>
                <IconContext.Provider
                  value={{ className: 'react-icon', size: '1.1em' }}
                >
                  <SaasProvider
                    linkComponent={linkComponent}
                    onError={onError}
                    theme={theme}
                  >
                    <AuthProvider {...authService}>
                      <FeaturesProvider value={segments}>
                        <I18nProvider>
                          <Hotkeys hotkeys={appHotkeys}>
                            <ModalsProvider>{children}</ModalsProvider>
                          </Hotkeys>
                        </I18nProvider>
                      </FeaturesProvider>
                    </AuthProvider>
                  </SaasProvider>
                </IconContext.Provider>
              </ApolloProviderWrapper>
            </PostHogProvider>
          </ClerkLoaded>
        </>
      )}
    </ClerkAuthProvider>
  )
}
