import { gql } from './gql'

export const GET_SCHEMA_OVERVIEW = gql(`
  query GetSchemaOverview($projectId: bigint!) {
    datasourceSchemaMetadataOverview(
      where: { datasource: { environment: { projectId: { _eq: $projectId } } } }
      orderBy: [
        { lastSeenAt: DESC }
        { createdAt: DESC }
        { tableName: ASC }
        { columnName: ASC }
      ]
      limit: 100000
    ) {
      schemaName
      tableName
      columnName
      lastSeenAt
      tableDescription
      columnDescription
      indexes
      createdAt
      constraints
      tableName
      dataType
      datasourceId
      id
      notNull
      objectType
      valueHash
      tenantId
      updatedAt
      datasource {
        environment {
          id
          name
          project {
            id
            name
          }
        }
      }
    }
  }
`)

export const RENDER_SCHEMA_GRAPH_D2 = gql(`
mutation renderSchemamap($datasourceId: String!) {
  renderDatasourceSchemaMap(datasourceId: $datasourceId) {
    d2Svg
    d2Text
  }
}
`)

export const GET_LATEST_SMOS_FOR_DS = gql(`
  query GetLatestSmosForDs($environmentId: bigint!, $datasourceId: bigint!) {
    datasourceSchemaMetadataOverview(
      where: { datasourceId: {_eq: $datasourceId} }
      orderBy: [
        { lastSeenAt: DESC }
        { createdAt: DESC }
        { tableName: ASC }
        { columnName: ASC }
      ]
      limit: 100000
    ) {
      schemaName
      tableName
      columnName
      lastSeenAt
      tableDescription
      columnDescription
      indexes
      createdAt
      constraints
      tableName
      dataType
      datasourceId
      id
      notNull
      objectType
      valueHash
      tenantId
      updatedAt
    }

    trackedTenantEnvironments(where: {environmentId: {_eq: $environmentId}}) {
      trackedTenant{
        shortName
        name
      }
    }
  }
`)
