import { gql } from './gql'

export const UPDATE_ADMIN_PROJECT_GOOGLE_OAUTH2_CREDS = gql(`
  mutation UpdateAdminProjectGoogleOauth2CredsProject(
    $projectId: String!
    $creds: GoogleOauth2CredsInput!
  ) {
    updateProjectGoogleOauth2Creds(projectId: $projectId, creds: $creds)
  }
`)

export const UPDATE_ADMIN_PROJECT_GOOGLE_SERVICE_ACCOUNT_CREDS = gql(`
  mutation UpdateAdminProjectGoogleServiceAccountCredsProject(
    $projectId: String!
    $creds: GoogleServiceAccountCredsInput!
  ) {
    updateProjectGoogleServiceAccountCreds(projectId: $projectId, creds: $creds)
  }
`)
