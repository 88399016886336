import { gql } from './gql'

export const GET_PROJECT_GOOGLE_DRIVE_FOLDERS = gql(`
  query GetProjectGoogleDriveFolders($projectId: String!) {
    listProjectGoogleDriveFolders(projectId: $projectId) {
      id
      name
    }
  }
`)

export const CREATE_GOOGLE_SPREADSHEET = gql(`
  mutation CreateGoogleSpreadsheet(
    $projectId: String!
    $environmentId: String!
    $spreadsheetName: String!
    $sheetName: String!
    $trackedMdeId: String!
    $trackedTenantId: String!
  ) {
    createGoogleSpreadsheet(
      projectId: $projectId
      environmentId: $environmentId
      spreadsheetName: $spreadsheetName
      sheetName: $sheetName
      trackedMdeId: $trackedMdeId
      trackedTenantId: $trackedTenantId
    ) {
      createdGoogleSpreadsheetId
    }
  }
`)

export const UPDATE_PROJECT_GOOGLE_DRIVE_FOLDER_ID = gql(`
  mutation UpdateProjectGoogleDriveFolderId(
    $projectId: bigint!
    $googleDriveFolderId: String
  ) {
    updateProjectsByPk(
      pkColumns: { id: $projectId }
      _set: { googleDriveFolderId: $googleDriveFolderId }
    ) {
      id
      googleDriveFolderId
    }
  }
`)
