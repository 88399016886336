import { gql } from './gql'

export const INVITE_TO_TENANT = gql(`
  mutation inviteToTenant($emails: [String!]!, $role: String!) {
    inviteToTenant(emails: $emails, role: $role)
  }
`)

export const REMOVE_USER_FROM_TENANT = gql(`
  mutation RemoveUserFromTenant($userId: String!, $tenantId: String!) {
    removeUserFromTenant(userId: $userId, tenantId: $tenantId)
  }
`)

export const CREATE_TENANT = gql(`
  mutation CreateTenant(
    $name: String!
    $slug: String!
    $email: String!
    $logo: String!
  ) {
    createTenant(
      args: { _name: $name, _slug: $slug, _email: $email, _logo: $logo }
    ) {
      id
      name
      slug
      email
      logo
    }
  }
`)
