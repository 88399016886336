import { gql } from './gql'
import { GoogleSpreadsheetReactFlowInput } from './gql/graphql'

export const GET_PROJECT_GOOGLE_SPREADSHEET_OVERVIEW = gql(`
  query ProjectGoogleSpreadsheetOverview($projectId: bigint!) {
    googleSpreadsheetOverview(
      args: { _project_id: $projectId }
      orderBy: [
        { googleSpreadsheet: { id: DESC_NULLS_LAST } }
        { trackedTenant: { name: ASC } }
        { trackedMde: { name: ASC } }
        { environment: { type: DESC } }
      ]
    ) {
      trackedMde {
        id
        name
        shortName
      }
      trackedTenant {
        id
        name
        shortName
      }
      environment {
        id
        name
      }
      trackedMdeEnvironment {
        id
        lastSyncedAt
      }
      trackedTenantEnvironment {
        id
        lastSyncedAt
      }
      googleSpreadsheet {
        id
        spreadsheetId
        project {
          name
          id
        }
        name
        googleScriptId
        trackedTenant {
          id
          name
          shortName
        }
        trackedMde {
          id
          name
          shortName
        }
        environment {
          id
          description
          name
        }

        googleSheets(orderBy: { sheetId: ASC }) {
          name
          lastSyncedAt
          id
        }
      }
    }
  }
`)

export const GET_PROJECT_SPREADSHEET = gql(`
  query GetGoogleSpreadsheet($id: bigint!) {
    googleSpreadsheetsByPk(id: $id) {
      id
      readonlyDatasourceId
      rwDatasourceId
      spreadsheetId
      name
      googleScriptId
      createdAt
      updatedAt

      project {
        name
        id
      }
      trackedTenant {
        id
        name
        shortName
      }
      trackedMde {
        id
        name
        shortName
      }
      environment {
        id
        description
        name
        datasources(orderBy: { id: ASC }) {
          id
          tenantId
          host
          port
          database
          username
          sslmode
          rootcert
          createdAt
          updatedAt
          lastConnectionAttemptAt
          lastSuccessfulConnectionAttemptAt
        }
      }
      googleSpreadsheetSyncs(
        where: { state: { _eq: COMPLETED } }
        orderBy: { finishedAt: DESC_NULLS_LAST }
        limit: 1
      ) {
        id
        state
        user {
          id
          name
        }
        requesterEmail
        requesterUserId
        flow
        syncType
        comment
        datasource {
          id
        }
      }

      syncSummary: googleSpreadsheetSyncs(
        orderBy: { finishedAt: DESC_NULLS_LAST }
        limit: 100
      ) {
        id
        state
        syncType
        createdAt
        updatedAt
        finishedAt
        user {
          id
          name
          pictureUrl
        }
        comment
        requesterEmail
        requesterUserId
        datasource {
          id
        }
      }

      googleSheets(orderBy: { sheetId: ASC }) {
        name
        lastSyncedAt
        id
      }
    }
  }
`)

export const DELETE_GOOGLE_SPREADSHEET = gql(`
  mutation DeleteGoogleSpreadsheet($googleSpreadsheetId: String!) {
    deleteGoogleSpreadsheet(googleSpreadsheetId: $googleSpreadsheetId)
  }
`)

export const SYNC_GOOGLE_SPREADSHEET = gql(`
  mutation SyncGoogleSpreadsheet(
    $spreadsheetId: String!
    $datasourceId: String!
    $flow: GoogleSpreadsheetReactFlowInput!
  ) {
    syncGoogleSpreadsheet(
      googleSpreadsheetId: $spreadsheetId
      datasourceId: $datasourceId
      flow: $flow
    )
  }
`)

export const READ_MDE = gql(`
  query readMDE($datasourceId: String!, $mdeName: String!, $limit: Int) {
    readMDE(datasourceId: $datasourceId, mdeName: $mdeName, limit: $limit) {
      columns
      rows
    }
  }
`)

export const EDGE_MARKER_FRAGMENT = gql(`
  fragment EdgeMarker on GoogleSpreadsheetReactFlowEdgeMarker {
    type
    color
    width
    height
    markerUnits
    orient
    strokeWidth
  }
`)

export const REACT_FLOW_EDGE_FRAGMENT = gql(`
  fragment ReactFlowEdge on GoogleSpreadsheetReactFlowEdge {
    id
    source
    target
    type
    animated
    selected
    markerEnd {
      ...EdgeMarker
    }
    markerStart {
      ...EdgeMarker
    }
    data
  }
`)

export const REACT_FLOW_NODE_FRAGMENT = gql(`
  fragment ReactFlowNode on GoogleSpreadsheetReactFlowNode {
    id
    type
    width
    height
    deletable
    draggable
    dragging
    parentNode
    expandParent
    extent
    selected
    position {
      x
      y
    }
    positionAbsolute {
      x
      y
    }
    style
    data
  }
`)

export const INFER_GOOGLE_SPREADSHEET = gql(`
  mutation InferGoogleSpreadsheet(
    $datasourceId: String!
    $googleSpreadsheetId: String!
    $flow: GoogleSpreadsheetReactFlowInput
  ) {
    inferGoogleSpreadsheet(
      datasourceId: $datasourceId
      googleSpreadsheetId: $googleSpreadsheetId
      flow: $flow
    ) {
      flow {
        viewport { x y zoom }
        edges {
          ...ReactFlowEdge
        }
        nodes {
          ...ReactFlowNode
        }
      }
    }
  }
`)
