import { gql } from './gql'
import { TrackedTenantsInsertInput } from './gql/graphql'

export const TRACK_TENANTS = gql(`
  mutation trackTenants($trackedTenants: [TrackedTenantsInsertInput!]!) {
    insertTrackedTenants(
      objects: $trackedTenants
      onConflict: {
        constraint: tracked_tenants_project_id_short_name_key
        updateColumns: [name]
      }
    ) {
      returning {
        id
        name
        shortName
        status
        trackedTenantEnvironments {
          id
          environmentId
          lastSyncedAt
          lastKnownTenantId
          tenantData
          tenantLocale
        }
      }
    }
  }
`)

export const GET_TRACKED_TENANTS = gql(`
  query GetTrackedTenants($projectId: bigint!) {
    trackedTenants(where: { projectId: { _eq: $projectId } }) {
      id
      name
      shortName
      status
      createdAt
      updatedAt
      project {
        id
        name
      }
      trackedTenantEnvironments {
        id
        environmentId
        environment {
          id
          name
        }
        lastSyncedAt
        lastKnownTenantId
        tenantData
        tenantLocale
      }
    }
  }
`)

export const TRACK_MDES = gql(`
  mutation trackMDEs($trackedMdes: [TrackedMdesInsertInput!]!) {
    insertTrackedMdes(
      objects: $trackedMdes
      onConflict: {
        constraint: tracked_mdes_project_id_short_name_key
        updateColumns: [name]
      }
    ) {
      returning {
        id
        name
        shortName
        status
        trackedMdeEnvironments {
          id
          environmentId
          lastSyncedAt
        }
      }
    }
  }
`)

export const GET_TRACKED_MDES = gql(`
  query GetTrackedMDEs($projectId: bigint!) {
    trackedMdes(where: { projectId: { _eq: $projectId } }) {
      id
      name
      shortName
      status
      createdAt
      updatedAt
      project {
        id
        name
      }
      trackedMdeEnvironments {
        id
        environmentId
        environment {
          id
          name
        }
        lastSyncedAt
      }
    }
  }
`)

export const GET_USAGE_BY_TRACKED_TENANTS = gql(`
  query GetUsageByTrackedTenants($projectId: bigint!) {
    trackedTenants(where: { projectId: { _eq: $projectId } }) {
      id
      name
      shortName
      status
      googleSpreadsheets {
        googleSpreadsheetSyncsAggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`)
