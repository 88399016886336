import { gql } from './gql'

export const GET_SUBSCRIPTION_PLANS = gql(`
  query GetSubscriptionPlans {
    subscriptionPlans(
      orderBy: [{ monthlyTransferMegabyteLimits: DESC, slug: DESC }]
    ) {
      id
      name
      slug
      description
      maxGoogleSheets
      maxTrackedTenants
      monthlyTransferMegabyteLimits
      billingIntervalMonths
    }
  }
`)
