import { gql } from './gql'

export const REMOTE_LIST_TENANTS = gql(`
  query RemoteListTenants($datasourceId: String!) {
    listTenants(datasourceId: $datasourceId) {
      id
      name
      shortName
      locale
      data
    }
  }
`)

export const REMOTE_LIST_MDES = gql(`
  query RemoteListMDEs($datasourceId: String!) {
    listMDEs(datasourceId: $datasourceId) {
      name
      shortName
    }
  }
`)

export const INFER_CANDIDATE_MDES = gql(`
  mutation InferCandidateMDES($datasourceIds: [String!]!) {
    inferMDECandidates(datasourceIds: $datasourceIds) {
      candidates {
        schema
        tableName
        tableClusterId
        depth
        naturalKeyConstraintAst
        dependencies
        neededBy
      }
    }
  }
`)

export const SYNC_DATASOURCE_I18N = gql(`
  mutation SyncDatasourceI18n($datasourceId: String!) {
    syncDatasourceI18n(datasourceId: $datasourceId)
  }
`)

export const SYNC_DATASOURCE_SMO = gql(`
  mutation SyncDatasourceSmo($datasourceId: String!, $refresh: Boolean!) {
    syncDatasourceSchemaMetadataOverview(
      datasourceId: $datasourceId
      refresh: $refresh
    )
  }
`)

export const DEPLOY_GAS = gql(`
  mutation DeployGas($googleSpreadsheetId: String!) {
    deployGASForGoogleSpreadsheet(googleSpreadsheetId: $googleSpreadsheetId)
  }
`)
