import * as React from 'react'

import { parseISO } from 'date-fns'

import { useAuth } from '@saas-ui/auth'

import {
  BillingOptions,
  BillingPlan,
  BillingStatus,
} from '@saas-ui-pro/billing'

import { useFeatures } from '@saas-ui-pro/feature-flags'
import { GET_CURRENT_USER, UserRolesEnum } from '@api/client'
import { PricingFeatureId, pricingFeatureIds } from '@app/config'
import { useQuery } from '@apollo/client'

function postgresDateRangeParse(range: string) {
  const [start, end] = range.split(' - ')
  return {
    start: parseISO(start),
    end: parseISO(end),
  }
}

export const useInitApp = () => {
  const auth = useAuth()
  const { isAuthenticated, isLoading, isLoggingIn } = auth
  const features = useFeatures()

  const {
    data: { currentUser: currentUsers, subscriptionPlans, projects } = {},
  } = useQuery(GET_CURRENT_USER)

  const currentUser = currentUsers && currentUsers[0]

  const tenant = currentUser?.userTenants?.find(
    (ut) => ut.tenant.id === currentUser?.lastTenantId,
  )?.tenant

  const subscription =
    tenant?.currentSubscription && tenant?.currentSubscription[0]

  const plans = React.useMemo(() => {
    return (subscriptionPlans || []).map((plan) => {
      const overageMbTransferEuro =
        plan.overageMbTransferEuroCent &&
        parseInt(plan.overageMbTransferEuroCent) / 100.0
      return {
        ...plan,
        status: 'active',

        period: plan.billingIntervalMonths == 1 ? 'monthly' : 'yearly',
        // TODO: fetch these features from the server
        features: {
          [pricingFeatureIds.googleSheets]: plan.maxGoogleSheets,
          [pricingFeatureIds.monthlyQuota]: `${plan.monthlyTransferMegabyteLimits} MB / month included`,
          [pricingFeatureIds.flexibleUsage]: overageMbTransferEuro
            ? `€${overageMbTransferEuro} or (€${
                overageMbTransferEuro * 1024
              } / GB)`
            : undefined,
          [pricingFeatureIds.trackedTenants]: plan.maxTrackedTenants,
          [pricingFeatureIds.slackIntegration]: true,
          [pricingFeatureIds.support]: plan.slug.includes('pro')
            ? 'Priority support'
            : '3-day email',
          [pricingFeatureIds.users]: 'unlimited',
          [pricingFeatureIds.tenantOnboardingMetrics]: true,
          [pricingFeatureIds.schemaOverview]: true,
          [pricingFeatureIds.successEngineer]: plan.billingIntervalMonths >= 12,
        } as Record<PricingFeatureId, boolean | string | number>,
        price: plan.priceEuroCent
          ? `€${parseInt(plan.priceEuroCent) / 100}`
          : 'Free',
      }
    }) as BillingPlan[]
  }, [subscriptionPlans])
  const freePlan = React.useMemo(() => {
    return plans.find((plan) => plan.slug === 'free')
  }, [plans])
  const planId = subscription?.subscriptionPlan?.id ?? freePlan?.id

  const billing: BillingOptions = React.useMemo(() => {
    return {
      plans: plans,
      currentPlan: plans.find((p) => p.id == planId) as BillingPlan,
      status: 'active' as BillingStatus,
      planId: planId,
      startedAt: subscription?.valid
        ? postgresDateRangeParse(subscription.valid).start
        : undefined,
    }
  }, [plans, planId, subscription?.valid])

  React.useEffect(() => {
    if (currentUser && tenant && subscription?.subscriptionPlan.slug) {
      features.identify({
        id: currentUser.id,
        // TODO: fetch the user roles from the server once there is more than one
        roles: currentUser.userTenants.flatMap(
          (ut) => ut.userRole,
        ) as UserRolesEnum[],
        plan: subscription?.subscriptionPlan.slug,
      })
    }
  }, [
    currentUser,
    currentUser?.id,
    features,
    subscription?.subscriptionPlan.slug,
    tenant,
  ])

  return {
    ...auth,
    currentUser,
    subscriptionPlans,
    projects,
    isInitializing:
      isLoading || isLoggingIn || (isAuthenticated && !!currentUser),
    isAuthenticated,
    billing,
  }
}
