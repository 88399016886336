import { gql } from './gql'

export const PREVIEW_POSTGRES_ENVIRONMENT_SYNC = gql(`
  mutation PreviewPostgresEnvironmentSync($sourceDatasourceId: String!, $trackedMdeId: String, $sourceTrackedTenantId: String, $sourceWhere: String, $targetDatasourceId: String!, $targetTrackedTenantId: String) {
    previewPostgresEnvironmentSync(sourceDatasourceId: $sourceDatasourceId, targetDatasourceId: $targetDatasourceId, sourceMdeWhere: $sourceWhere, sourceTrackedTenantId: $sourceTrackedTenantId, targetTrackedTenantId: $targetTrackedTenantId, trackedMdeId: $trackedMdeId) {
      sourceMDEs {
        columns
        rows
      }
      targetMDEs {
        columns
        rows
      }
      recordCount
      d2LODSvgs {
        lod
        d2Svg
        d2Text
      }

      flow {
        viewport {
          x
          y
          zoom
        }
        edges {
          ...ReactFlowEdge
        }
        nodes {
          ...ReactFlowNode
        }
      }
    }
  }
`)

export const SYNC_POSTGRES_ENVIRONMENT = gql(`
mutation SyncPostgresEnv($sourceDatasourceId: String!, $trackedMdeId: String, $sourceTrackedTenantId: String, $sourceMdeWhere: String, $targetDatasourceId: String!, $targetTrackedTenantId: String) {
  syncPostgresEnvironment(sourceDatasourceId: $sourceDatasourceId, targetDatasourceId: $targetDatasourceId, sourceMdeWhere: $sourceMdeWhere, sourceTrackedTenantId: $sourceTrackedTenantId, targetTrackedTenantId: $targetTrackedTenantId, trackedMdeId: $trackedMdeId) {
    dataMigration {
        createdAt
        id
        importStartedAt
        importedAt
        mdeName
        requesterEmail
        requesterUserId
        source
        state
        summary
        tenantId
        tenantName
        updatedAt
        version
    }
    syncDurationSeconds
    syncedTotalMegabytes
    syncedTotalRecords
  }
}
`)
