import { gql } from './gql'

export const GET_PROJECT = gql(`
  query GetProject($id: bigint!) {
    projectsByPk(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
      googleDriveFolderId
    }
  }
`)

export const CREATE_PROJECT = gql(`
  mutation CreateProject($project: ProjectsInsertInput!) {
    insertProjectsOne(object: $project) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`)

export const DELETE_PROJECT = gql(`
  mutation DeleteProject($id: bigint!) {
    deleteProjectsByPk(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`)
