import { PricingFeature } from '@app/features/billing/components/pricing-table'

export enum pricingFeatureIds {
  'monthlyQuota' = 'monthlyQuota',
  'trackedTenants' = 'trackedTenants',
  'googleSheets' = 'googleSheets',
  'support' = 'support',
  'users' = 'users',
  'slackIntegration' = 'slackIntegration',
  'flexibleUsage' = 'flexibleUsage',
  'schemaOverview' = 'schemaOverview',
  'tenantOnboardingMetrics' = 'tenantOnboardingMetrics',
  'successEngineer' = 'successEngineer',
}

export type PricingFeatureId = `${pricingFeatureIds}`
interface Feature extends PricingFeature {
  id: PricingFeatureId
  label: string
  description: string
}

export const features: Feature[] = [
  {
    id: 'monthlyQuota',
    label: 'Monthly quota',
    description:
      'The amount of Postgres binary data that you can transfer each month.',
  },
  {
    id: 'flexibleUsage',
    label: 'Flexible usage pricing',
    description:
      'The price per MB for any additional Postgres data transferred.',
  },
  {
    id: 'trackedTenants',
    label: 'Tracked tenants',
    description:
      'The number of tenants you can track and create integrations for.',
  },
  {
    id: 'googleSheets',
    label: 'Google Sheets',
    description: 'The number of Google Sheets that you can manage.',
  },
  {
    id: 'successEngineer',
    label: 'Success Engineer',
    description:
      'Access to a success engineer to help you onboard and succeed.',
  },
  {
    id: 'support',
    label: 'Support',
    description: 'The level of support you receive.',
  },
  {
    id: 'users',
    label: 'Users',
    description: 'The number of users that can collaborate on Schemamap.io.',
  },
  {
    id: 'schemaOverview',
    label: 'Schema Overview',
    description: 'Access to schema overviews dasbhoard between environments.',
  },
  {
    id: 'tenantOnboardingMetrics',
    label: 'Tenant Onboarding Metrics',
    description:
      'Access to tenant specific dashboards and progress tracking, so you never churn one.',
  },
  {
    id: 'slackIntegration',
    label: 'Slack Integration',
    description:
      'Whether you get push notifications about syncs/tenant onboarding milestones.',
  },
]
