import { AvailableProviders } from '@saas-ui/auth'

import { FaGoogle } from 'react-icons/fa'

/**
 * The authentication type, magiclink or password
 */
export const authType = 'magiclink'

/**
 * Available OAuth providers for single sign on.
 */
export const authProviders: AvailableProviders = {
  google: {
    icon: FaGoogle,
    name: 'Google',
  },
}

/**
 * This maps routes to the corresponding auth view in the `Auth` component.
 */
export const authPaths: Record<string, any> = {
  '/login': {
    view: 'login',
    title: 'Log in to Schemamap.io',
  },
  '/signup': {
    view: 'signup',
    title: 'Sign up for Schemamap.io',
  },
}
