import { gql } from './gql'

export const GET_USER = gql(`
  query GetUser($id: bigint!) {
    usersByPk(id: $id) {
      id
      givenName
      familyName
      name
      email
      pictureUrl
      emailVerified
    }
  }
`)

export const GET_USERS = gql(`
  query GetUsers($where: UsersBoolExp!) {
    users(where: $where) {
      id
      givenName
      familyName
      name
      email
      pictureUrl
      emailVerified
    }
  }
`)

export const GET_CURRENT_USER = gql(`
  query GetCurrentUser {
    currentUser {
      id
      givenName
      familyName
      name
      email
      pictureUrl
      lastTenantId

      userTenants {
        tenant {
          slug
          id
          clerkId
          name
          logo
          hasSlackIntegration
          hasGoogleOauthIntegration
          currentSubscription {
            subscriptionPlan {
              name
              description
              id
              slug
              lemonsqueezyProductId
              lemonsqueezyCheckoutId
              billingIntervalMonths
            }
            valid
            maxGoogleSheets
            maxGoogleSheets
            monthlyTransferMegabyteLimits
            overageMbTransferEuroCent
            priceEuroCent
          }
          userTenants {
            user {
              id
              name
              email
              status
            }
          }
        }
        user {
          id
          name
          familyName
          givenName
          email
          emailVerified
          status
        }
        userRole
      }
    }

    projects(orderBy: { id: ASC }) {
      id
      name
      description
      createdAt
      updatedAt
      googleDriveFolderId
      environments {
        id
        name
        type
        description
        createdAt
        updatedAt
        project { id name createdAt updatedAt }
        datasources {
          id
          tenantId
          host
          port
          database
          username
          sslmode
          rootcert
          createdAt
          updatedAt
          lastConnectionAttemptAt
          lastSuccessfulConnectionAttemptAt
        }
        pgtunnelPorts {
        id
        port
        token
        createdAt
        updatedAt
      }
        trackedTenantEnvironments {
        lastSyncedAt
        trackedTenant {
          id
          name
          shortName
          status
          createdAt
          updatedAt
        }
      }
      trackedMdeEnvironments {
        lastSyncedAt
        trackedMde {
          id
          name
          shortName
          status
          createdAt
          updatedAt
        }
      }
      }
      trackedTenants {
        id
        name
        status
        shortName
      }
      trackedMdes {
        id
        name
        status
        shortName
      }
    }

    subscriptionPlans {
      name
      description
      id
      slug
      lemonsqueezyProductId
      lemonsqueezyCheckoutId
      overageMbTransferEuroCent
      priceEuroCent
      billingIntervalMonths
      maxGoogleSheets
      maxTrackedTenants
      monthlyTransferMegabyteLimits
    }
  }
`)

export const UPDATE_USER = gql(`
  mutation UpdateUser($user: UsersInsertInput!) {
    insertUsersOne(
      object: $user
      onConflict: {
        constraint: users_pkey
        updateColumns: [givenName, familyName, name, email]
      }
    ) {
      id
      givenName
      familyName
      name
      email
      emailVerified
    }
  }
`)

export const CREATE_USER = gql(`
  mutation CreateUser($user: UsersInsertInput!) {
    insertUsersOne(object: $user) {
      id
      givenName
      familyName
      name
      email
      pictureUrl
      emailVerified
    }
  }
`)
