export const SlackConnectUrl = (tenantId: string) => {
  const SLACK_OAUTH_BASE_URL = 'https://slack.com/oauth/v2/authorize'
  const origin =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : ''
  const REDIRECT_URI = origin + '/api/slack/callback'

  const state = {
    redirect_uri: REDIRECT_URI,
    tenantId: tenantId.toString(),
    redirect_to: window.location.pathname,
  }

  // Encode the state
  const encodedState = btoa(JSON.stringify(state))

  const params = {
    client_id: process.env.NEXT_PUBLIC_SLACK_CLIENT_ID as string,
    scope:
      'incoming-webhook,chat:write,reactions:read,reactions:write,commands',
    redirect_uri: REDIRECT_URI,
    state: encodedState,
  }

  return SLACK_OAUTH_BASE_URL + '?' + new URLSearchParams(params).toString()
}
