import { Stack, Text, Progress, Card, CardBody, Box } from '@chakra-ui/react'
import { useBilling } from '@saas-ui-pro/billing'
import { Has } from '@saas-ui-pro/feature-flags'

import { LinkButton } from '@ui/lib'
import { usePath } from '../hooks/use-path'

export const BillingStatus = () => {
  const { currentPlan } = useBilling()

  const upgradePath = usePath('/settings/plans')
  const isFreePlan = currentPlan?.slug === 'free'
  if (!isFreePlan) return null

  // TODO: Get the actual capacity used
  const capacityUsed = undefined

  return (
    <Box p={2}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Text color="muted" fontSize="xs" flex={1}>
          <Text as={'div'}>Current plan:</Text>
          <Text as={'div'} fontWeight="bold">
            {currentPlan.name}
          </Text>
        </Text>

        {currentPlan?.slug == 'free' && (
          <LinkButton
            flex={1}
            href={upgradePath}
            variant="solid"
            colorScheme="green"
            size="sm"
            flexGrow={1}
          >
            Upgrade
          </LinkButton>
        )}
      </Stack>
      {capacityUsed !== undefined && (
        <Progress
          position="absolute"
          bottom="0"
          right="0"
          left="0"
          colorScheme="green"
          bg="sidebar-on-muted"
          size="xs"
          borderRadius="0"
          value={capacityUsed}
        />
      )}
    </Box>
  )
}
