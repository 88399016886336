import { Flex, FlexProps } from '@chakra-ui/react'
import React from 'react'

import { SchemamapLogo } from './schemamap-logo'

export const Logo = (props: FlexProps & { logo?: React.ReactNode }) => {
  const { logo } = props
  return (
    <Flex width="100px" {...props}>
      {logo || <SchemamapLogo />}
    </Flex>
  )
}
