import { SchemamapLogoGlyph } from '../logo/schemamap-logo-glyph'

import { LoadingOverlay, LoadingOverlayProps } from '@saas-ui/react'
import { keyframes } from '@chakra-ui/react'
import { Logo } from '../logo'

const scale = keyframes`
  0% {
    scale: 1.5;
  }
  100% {
    scale: 1;
  }
`

/**
 * Show a fullscreen loading animation while the app is loading.
 */
export const AppLoader: React.FC<LoadingOverlayProps> = (props) => {
  return (
    <LoadingOverlay
      {...props}
      variant="fullscreen"
      children={
        <Logo margin="0 auto" mb="12" animation={`5s ease-out ${scale}`} />
      }
    />
  )
}
