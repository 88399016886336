import { gql } from './gql'

export const GET_ENVIRONMENTS_FOR_PROJECT = gql(`
  query GetEnvironmentsForProject($projectId: bigint!) {
    environments(where: { projectId: { _eq: $projectId } }) {
      id
      name
      type
      description
      createdAt
      updatedAt
    }
  }
`)

export const GET_ENVIRONMENT_FOR_PROJECT = gql(`
  query GetEnvironmentForProject($projectId: bigint!, $environmentId: bigint!) {
    environments(
      where: { projectId: { _eq: $projectId }, id: { _eq: $environmentId } }
    ) {
      id
      name
      type
      description
      createdAt
      updatedAt
      project {
        id
        name
        description
        createdAt
        updatedAt
      }
      pgtunnelPorts {
        id
        port
        token
        createdAt
        updatedAt
      }
      datasources(orderBy: { id: ASC }) {
        id
        tenantId
        host
        port
        database
        username
        sslmode
        rootcert
        createdAt
        updatedAt
        lastConnectionAttemptAt
        lastSuccessfulConnectionAttemptAt
      }
      trackedTenantEnvironments {
        lastSyncedAt
        trackedTenant {
          id
          name
          shortName
          status
          createdAt
          updatedAt
        }
      }
      trackedMdeEnvironments {
        lastSyncedAt
        trackedMde {
          id
          name
          shortName
          status
          createdAt
          updatedAt
        }
      }
    }
  }
`)

export const CREATE_ENVIRONMENTS = gql(`
  mutation CreateEnvironments($environments: [EnvironmentsInsertInput!]!) {
    insertEnvironments(objects: $environments) {
      returning {
        id
        name
        description
        type
      }
    }
  }
`)

export const DELETE_ENVIRONMENT = gql(`
  mutation DeleteEnvironment($id: bigint!) {
    deleteEnvironmentsByPk(id: $id) {
      id
      name
      description
      projectId
    }
  }
`)
